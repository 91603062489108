html {
  font-family: 'Roboto',
  sans-serif;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(244, 244, 244);
  color: #333;
}

p {
  font-size: 1.6rem;
}

small {
  font-size: 1.2rem;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px;
}

.title {
  text-align: center;
  font-size: 70px;
  font-family: 'Pacifico', 'Serif';
}

.form {
  display: grid;
}

.label {
  font-size: 1.5rem;
  margin-bottom: 0.2rem;

}

.input {
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  line-height: 2.8rem;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
}

.button {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 1rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  font-size: 1.4rem;
  cursor: pointer;
  transition: background-color 250ms;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.85);
}

.col-6 {
  border:2px solid black;
  padding:1rem;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px
}

.card-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  list-style: none;
  padding-left: 0;
}
.card-list img {
  width: 150px;
}


h3 {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

div.cute-banner {
  text-align: center;
  width: 500px;
  height: 200px;
  margin: auto;
}

div.nomination-banner {
  text-align: center;
  width: 500px;
  margin: auto;
  border: 3px solid rgb(233, 126, 32);
}